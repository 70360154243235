import { Quote } from '@core/api';

import { getPrice } from './paymentCalculations';
import {
  calculateDiscount,
  calculateMonthlyPremiumBeforeDiscount,
  calculateYearlyPremiumBeforeDiscount
} from './premiumCalculations';
import { PaymentPeriodId } from './types';
export const getQuoteResultProps = (
  quote: Quote | null,
  paymentPeriodId: PaymentPeriodId
) => {
  if (!quote) {
    return;
  }
  const discount = calculateDiscount(quote);
  const isMonthlyPayment = paymentPeriodId === '2';

  return {
    price: getPrice(paymentPeriodId, {
      monthlyPrice: quote.monthlyPremium,
      yearlyPrice: quote.premium
    }),
    discount: discount,
    campaignCode: quote.campaignCode ?? undefined,
    firstPremiumAmount: quote.firstPremiumAmount ?? undefined,
    premiumBeforeDiscount: isMonthlyPayment
      ? calculateMonthlyPremiumBeforeDiscount(quote)
      : calculateYearlyPremiumBeforeDiscount(quote),
    policyStartDate: quote.homeData.policyStartDate
  };
};
