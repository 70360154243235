import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { Button } from '@components/index';
import { Block, MaxWidthContainer } from '@features/onboarding-2';

import { FormDataDisplay } from '../';
import { isPhoneNumber } from '../../../../../../lib/utils';
import OnboardingTextFieldSimple from '../../../../components/OnboardingTextFieldSimple';
import { useSummaryPageTranslation } from '../../../../translation';
import { FormData } from '../../types';
import styles from './FormSection.module.css';
import SummaryEmailInput from './components/SummaryEmailInput';

interface FormSectionProps {
  formData?: FormData;
  setIsButtonDisabled: (isDisabled: boolean) => void;
  onChangeDetailsClick: () => void;
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
}

const FormSection = ({
  formData,
  setIsButtonDisabled,
  onChangeDetailsClick,
  register,
  errors
}: FormSectionProps) => {
  const t = useSummaryPageTranslation();

  const handleButtonDisabled = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsButtonDisabled(!event.target?.value);
  };

  return (
    <Block className={styles.block}>
      <MaxWidthContainer>
        <div className={styles.blockContent}>
          <h4>{t('HEADING')}</h4>
          <FormDataDisplay formData={formData} />
          <Button
            variant="tertiary"
            hugged
            className={styles.changeDetailsButton}
            onClick={() => onChangeDetailsClick()}
          >
            {t('CHANGE_DETAILS_BUTTON')}
          </Button>
          <div className={styles.inputs}>
            <SummaryEmailInput
              {...register('emailId', {
                onChange: handleButtonDisabled
              })}
              required
            />
            <OnboardingTextFieldSimple
              {...register('mobileNumber', {
                pattern: {
                  value: isPhoneNumber,
                  message: t('ERRORS.INVALID_PHONE_NUMBER')
                }
              })}
              id="phone"
              label={t('LABELS.PHONE_NUMBER')}
              type="tel"
              error={errors.mobileNumber?.message}
            />
          </div>
        </div>
      </MaxWidthContainer>
    </Block>
  );
};

export default FormSection;
