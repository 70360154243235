import {
  CalculateQuoteResponse,
  CheckSimulationStatusRequest,
  CheckSimulationStatusResponse,
  CreatePaymentResponse
} from '@core/api';
import { ApiClientError } from '@lib/api';
import { CalculateQuoteBody } from '@pages/api/onboarding/calculate-quote';
import InternalApiError from '@lib/errors';
import { CreateQuoteBody } from '@core/api-insurance-in-a-box';

export const INVALID_POSTAL_CODE_ERROR = 'Invalid postal code';

export const simulateQuote = async (
  quoteData: CheckSimulationStatusRequest
): Promise<CheckSimulationStatusResponse['result']> => {
  const response = await fetch('/api/onboarding/simulate-quote', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(quoteData)
  });

  if (!response?.ok) {
    throw new Error('Invalid simulation');
  }

  const { data } = await response.json();

  if (!data?.result?.isValidSimulation) {
    throw new Error(INVALID_POSTAL_CODE_ERROR);
  }

  return data.result;
};

export const callCalculateQuote = async (
  quoteData: CalculateQuoteBody
): Promise<CalculateQuoteResponse['result']> => {
  const response = await fetch('/api/onboarding/calculate-quote', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(quoteData)
  });

  if (!response.ok) {
    throw new Error('Invalid quote calculation');
  }

  const data = await response.json();
  return data.result;
};

export const callCreatePayment = async (
  data: CreateQuoteBody
): Promise<CreatePaymentResponse['result'] | ApiClientError> => {
  const response = await fetch('/api/onboarding/create-payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new InternalApiError(responseData);
  }

  return responseData.result;
};
