interface InternalApiErrorOptions {
  code: number;
  message: string;
  cause?: Error;
}

class InternalApiError extends Error {
  code: number;
  cause?: Error;

  constructor(options: InternalApiErrorOptions) {
    super(options.message);
    this.code = options.code;
    this.cause = options.cause;
    this.name = 'InternalApiError';
  }
}

export default InternalApiError;
