import Image2 from '@assets/images/what-is-sejfa.webp';
import Image1 from '@assets/images/wow-2.webp';
import Image3 from '@assets/images/wow.webp';
import CampaignPageSection, {
  CampaignPageSectionProps
} from '@components/CampaignPageSection/CampaignPageSection';
import { useTranslation } from '@core/translation';
import { useLandingPageNavigation } from '@features/landing-page';
import { uuid } from '@lib/utils';

import { DISCOUNT_CODES_BY_DISCOUNT } from '../constants';

type Section = CampaignPageSectionProps & {
  id: string;
};

const CampaignPageSections = () => {
  const ct = useTranslation('COMMON');
  const { navigateToOnboarding } = useLandingPageNavigation();

  const onboardingUrl = `/onboarding?campaign=${DISCOUNT_CODES_BY_DISCOUNT[0]}`;

  const sections: Array<Section> = [
    {
      id: uuid(),
      title: 'Teckna 12 månader med Sejfa så bjuder vi på hela festivalen!',
      desc: 'En smart, digital och trygg hemförsäkring för dig, ditt hem och dina prylar. I sejfa ingår prylförsäkring upp till 1 miljon kr, reseförsäkring i 45 dagar och bostadsrättstillägg.',
      src: Image2,
      rowReverse: false,
      titleClassName: 'md:text-[3.5rem] font-bold',
      imgContainerClassName: 'order-last pt-0',
      blockClassName: 'space-y-4',
      pill: {
        text: '*Passa på!* Erbjudandet gäller bara till 31 Mars 2025'
      }
    },
    {
      id: uuid(),
      title: 'Så här gör du',
      titleClassName: 'md:text-[3.5rem] font-bold md:-ml-4',
      desc: `I samarbete med Way Out West erbjuder Sejfa nu ett tredagarspass till festivalen när du tecknar 12 månaders hemförsäkring. Teckna Sejfa med kampanjkoden [EARLYBIRD](${onboardingUrl}). När du har skaffat din nya hemförsäkring får du inom kort ett bekräftelsemail med information gällande din biljett.`,
      descClassName: 'md:-ml-4',
      src: Image1,
      rowReverse: true,
      cta: () => navigateToOnboarding(DISCOUNT_CODES_BY_DISCOUNT[0]),
      ctaTitle: ct('SIGN_HERE'),
      ctaDesc: 'Erbjudandet gäller bara till 31 Mars 2025',
      ctaDescClassName: 'text-[1rem] mt-8',
      ctaClassName: 'md:max-w-[286px] md:mt-6',
      imgContainerClassName: 'order-first pt-0'
    },
    {
      id: uuid(),
      title: 'Regler och villkor',
      titleClassName: 'md:text-[3.5rem] font-bold',
      desc: '',
      src: Image3,
      rowReverse: false,
      listClassName: 'text-xl md:text-2xl',
      list: [
        'Villkor för att du ska få en WOW biljett är att du har din hemförsäkring tecknad hos Sejfa 12 månader framåt, förutsatt att du fortsatt har ett behov av försäkring som Sejfas försäkring täcker. Skulle du säga upp din försäkring innan 12 månader har gått från dagen då försäkringen tecknades blir du återbetalningsskyldig för värdet av WOW biljetten (fr. 2.595 kr)',
        'Du kan inte vara kund hos Sejfa sen tidigare',
        'Endast ett erbjudande per hushåll kan användas',
        'Kampanjen gäller till och med 31 Mars 2025'
      ]
    }
  ];

  return (
    <>
      {sections.map(({ id, ...props }) => (
        <CampaignPageSection key={id} {...props} />
      ))}
    </>
  );
};

export default CampaignPageSections;
