import { useState, forwardRef } from 'react';

import OnboardingTextFieldSimple from '../../../../../../components/OnboardingTextFieldSimple';
import { useSummaryPageTranslation } from '../../../../../../translation';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SummaryEmailInput = forwardRef<HTMLInputElement, Props>(
  ({ id = 'email', onChange, className, ...props }, ref) => {
    const t = useSummaryPageTranslation();

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
      t('NO_EMAIL')
    );

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
      setEmail(event.target.value);

      if (!event.target.value) {
        setErrorMessage(t('NO_EMAIL'));
      }

      if (event.target.value && event.target.value !== email && errorMessage) {
        setErrorMessage(undefined);
      }
    };

    return (
      <OnboardingTextFieldSimple
        id={id}
        label={t('LABELS.EMAIL')}
        ref={ref}
        className={className}
        onChange={handleEmailChange}
        error={errorMessage}
        type="email"
        {...props}
      />
    );
  }
);

SummaryEmailInput.displayName = 'SummaryEmailInput';

export default SummaryEmailInput;
