import { createEventTracker } from '@core/tracking';

export type OnboardingEvent =
  | 'web_selected_apartment_onboarding_2'
  | 'web_is_renting_house_selected_onboarding_2'
  | 'web_is_owning_house_selected_onboarding_2'
  | 'web_entered_age_onboarding_2'
  | 'web_entered_personal_number_onboarding_2'
  | 'web_entered_personal_number_success_onboarding_2'
  | 'web_changed_prefilled_address_onboarding_2'
  | 'web_changed_prefilled_age_onboarding_2'
  | 'web_changed_prefilled_postal_code_onboarding_2'
  | 'web_submitted_form_without_personal_number_onboarding_2'
  | 'web_changed_start_date_onboarding_2'
  | 'web_added_campaign_code_onboarding_2'
  | 'web_clicked_banner_bank_id_onboarding_2'
  | 'web_successfully_identified_with_bank_id_onboarding_2'
  | 'web_clicked_confirm_change_information_onboarding_2'
  | 'web_selected_yearly_payment_onboarding_2'
  | 'web_selected_monthly_payment_onboarding_2'
  | 'web_navigated_to_nets_payment_onboarding_2';

export const useTrackEvent =
  createEventTracker<OnboardingEvent>('Onboarding-2');
