import { useEffect, useState } from 'react';

import { CalculateQuoteResponse } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { storage } from '@features/onboarding-2';
import { logger } from '@lib/logger';
import { useToggle } from '@lib/utils';
import { CalculateQuoteBody } from '@pages/api/onboarding/calculate-quote';

import { callCalculateQuote } from '../../Onboarding/quoteService';

export const handleCalculateQuote = async (
  calculateQuoteRequestData: CalculateQuoteBody
) => {
  try {
    return await callCalculateQuote(calculateQuoteRequestData);
  } catch (error) {
    logger.error('Error calculating quote:', error);
  }
};

const useCalculateQuoteRequest = () => {
  const { state: isLoading, close: stopLoading } = useToggle(true);
  const [response, setResponse] = useState<
    CalculateQuoteResponse['result'] | null
  >(null);
  const { addMessage } = useMessaging();
  const et = useTranslation('ERROR');

  useEffect(() => {
    async function fetchData() {
      const {
        postalCode,
        residents,
        address,
        discountCode,
        campaignCode,
        policyStartDate
      } = storage().onboarding;

      try {
        const response = await handleCalculateQuote({
          homeData: {
            postalCode: Number(postalCode),
            numberOfResidents: residents,
            address: address || 'Dummy address 123',
            policyStartDate
          },
          discountCode,
          campaignCode
        });

        response && setResponse(response);
      } catch (error) {
        logger.error('Error calculating quote:', error);
        addMessage({
          toastType: 'error',
          message: et('UNEXPECTED_ERROR')
        });
      } finally {
        stopLoading();
      }
    }
    fetchData();
  }, [stopLoading, addMessage, et]);

  return { response, isLoading }; // TODO: update name of response
};

export default useCalculateQuoteRequest;
