import { useState } from 'react';

import { Quote } from '@core/api';
import {
  ImportantDocumentsSection,
  storage,
  useOnboarding2,
  useTrackEvent
} from '@features/onboarding-2';
import { removeCountryCode, useToggle } from '@lib/utils';
import { NextPageWithLayout } from '@pages/_app';

import { useSummaryPageTranslation } from '../../translation';
import ChangeDetailsModal from './components/ChangeDetailsModal';
import ConditionalPayLaterButton from './components/ConditionalPayLaterButton';
import FormSection from './components/FormSection/';
import QuoteResultSection from './components/OuoteResultSection/QuoteResultSection';
import PayLaterModal from './components/PayLaterModal';
import { getDefaultFormData } from './formData';
import { getQuoteResultProps } from './getQuoteResultProps';
import useCalculateQuoteRequest from './hooks/useCalculateQuoteRequest';
import useCreatePaymentRequest from './hooks/useCreatePaymentRequest';
import useFormSetup from './hooks/useFormSetup';
import useIsClient from './hooks/useIsClient';
import { getUnit } from './paymentCalculations';
import styles from './Summary.module.css';
import { type FormData } from './types';

const transformCalculateQuoteResponse = (
  calculateQuoteResponse: Quote,
  formData: FormData
) => {
  return {
    ...calculateQuoteResponse,
    userInfo: {
      ...calculateQuoteResponse.userInfo,
      emailId: formData.emailId,
      countryCallingCode: '46',
      mobileNumber: formData.mobileNumber
        ? removeCountryCode(formData.mobileNumber)
        : formData.mobileNumber
    },
    homeData: {
      ...calculateQuoteResponse.homeData,
      paymentPeriodId: Number(formData.paymentPeriodId)
    }
  };
};

const Summary: NextPageWithLayout = () => {
  const isOnboarding2On = useOnboarding2();
  const { trackEvent } = useTrackEvent();

  const t = useSummaryPageTranslation();
  const [isPayLaterModalOpen, openPayLaterModal, closePayLaterModal] =
    useToggle();
  const [
    isChangeDetaiLsModalOpen,
    openChangeDetailsModal,
    closeChangeDetailsModal
  ] = useToggle();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const isClient = useIsClient();

  const { register, getValues, watch, handleSubmit, formState } = useFormSetup({
    ...getDefaultFormData(),
    ...storage().onboarding
  });

  const paymentPeriodId = watch('paymentPeriodId');

  const { pay, isLoading: isPaymentLoading } = useCreatePaymentRequest();

  const { response: calculateQuoteResponse, isLoading: isQuoteLoading } =
    useCalculateQuoteRequest();

  const saveUserInfoToSessionStorage = (email: string, givenName: string) => {
    storage().saveOnboardingStore({
      email,
      givenName
    });
  };

  const onSubmit = (formData: FormData) => {
    if (!calculateQuoteResponse) {
      return;
    }

    saveUserInfoToSessionStorage(
      formData.emailId,
      calculateQuoteResponse.userInfo.givenName
    );

    if (paymentPeriodId === '1') {
      trackEvent('web_selected_yearly_payment_onboarding_2');
    } else {
      trackEvent('web_selected_monthly_payment_onboarding_2');
    }

    const quoteToPay: Quote = transformCalculateQuoteResponse(
      calculateQuoteResponse,
      formData
    );

    pay(quoteToPay);
  };

  const quoteResultProps = getQuoteResultProps(
    calculateQuoteResponse,
    paymentPeriodId
  );

  const formatPayLaterFormDataValues = () => {
    return calculateQuoteResponse
      ? transformCalculateQuoteResponse(calculateQuoteResponse, getValues())
      : null;
  };

  if (!isClient || !isOnboarding2On) {
    return null; // This is needed due to hydration errors because we're using localStorage to prefill data.
  }

  return (
    <div className={styles.summaryPage}>
      <FormSection
        register={register}
        formData={getValues()}
        setIsButtonDisabled={setIsButtonDisabled}
        onChangeDetailsClick={openChangeDetailsModal}
        errors={formState.errors}
      />

      <QuoteResultSection
        {...quoteResultProps}
        policyStartDate={
          quoteResultProps?.policyStartDate ?? new Date().toISOString()
        }
        unit={getUnit(paymentPeriodId, {
          monthlyText: t('PRICE_PER_MONTH'),
          yearlyText: t('PRICE_PER_YEAR')
        })}
        paymentPeriodId={paymentPeriodId}
        isButtonDisabled={isButtonDisabled}
        handlePayment={handleSubmit(onSubmit)}
        register={register}
        isQuoteLoading={isQuoteLoading}
        renderExtraButton={() => (
          <ConditionalPayLaterButton
            isDisabled={isButtonDisabled}
            paymentPeriodId={paymentPeriodId}
            onClick={openPayLaterModal}
          />
        )}
        isPaymentLoading={isPaymentLoading}
      />

      <ImportantDocumentsSection />

      <PayLaterModal
        isOpen={isPayLaterModalOpen}
        closeModal={closePayLaterModal}
        formData={formatPayLaterFormDataValues()}
      />

      <ChangeDetailsModal
        isOpen={!isPayLaterModalOpen && isChangeDetaiLsModalOpen}
        closeModal={closeChangeDetailsModal}
      />
    </div>
  );
};

export default Summary;
