import { useRouter } from 'next/router';

export const useCurrentPage = () => {
  const { pathname } = useRouter();
  const isOnboarding = pathname.includes('/onboarding');
  const isSafetyByCiliaPage =
    pathname.includes('/safety-by-cilia') && !pathname.includes('/success');
  const isAccordPage = pathname.includes('/accord');

  return {
    isOnboarding,
    isSafetyByCiliaPage,
    isAccordPage
  };
};
