import { useMemo } from 'react';

import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { useTranslation } from '@core/translation';
import {
  InputMessageProps,
  OnboardingTextField,
  usePriceSuggestionFormErrorsTranslation,
  usePriceSuggestionLabelsTranslation
} from '@features/onboarding-2';

import { OnboardingFormFields } from '../../../OnboardingContainer';
import { OnboardingDropdown } from '../../../OnboardingDropdown';

type AddressFieldsProps = {
  register: UseFormRegister<OnboardingFormFields>;
  errors?: FieldErrors<OnboardingFormFields>;
  invalidPostalCodeMessage?: InputMessageProps;
  disabledFields?: {
    address: boolean;
    postalCode: boolean;
  };
  setCountyPostalCode: (value: string | null) => void;
};

const getCounties = (t: ReturnType<typeof useTranslation>) => [
  {
    label: t('VASTRA_GOTALAND'),
    value: '41664'
  },
  {
    label: t('HALLAND'),
    value: '30245'
  },
  {
    label: t('STOCKHOLM'),
    value: '10251'
  },
  {
    label: t('UPPSALA'),
    value: '75308'
  },
  {
    label: t('VARMLAND'),
    value: '65226'
  },
  {
    label: t('ALVSBORG'),
    value: '43135'
  },
  {
    label: t('OSTRA_GOTALAND'),
    value: '70211'
  }
];

const getErrorMessage = (fieldError?: { message?: string }) =>
  fieldError?.message ? { title: fieldError.message } : undefined;

const AddressFields = ({
  register,
  errors,
  invalidPostalCodeMessage,
  disabledFields,
  setCountyPostalCode
}: AddressFieldsProps) => {
  const labelsTranslations = usePriceSuggestionLabelsTranslation();
  const ct = useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.COUNTIES');
  const et = usePriceSuggestionFormErrorsTranslation();
  const counties = useMemo(() => getCounties(ct), [ct]);

  const createRequiredValidationRule = (
    field: keyof Pick<OnboardingFormFields, 'address' | 'postalCode'>
  ) => ({
    value: !disabledFields?.[field],
    message: !disabledFields?.[field] ? et('THIS_FIELD_IS_REQUIRED') : ''
  });

  const createPostalCodePatternValidationRule = () =>
    !disabledFields?.postalCode
      ? {
          value: /^\d{5}$/,
          message: et('POSTAL_CODE_LENGTH_ERROR')
        }
      : undefined;

  return (
    <>
      <OnboardingTextField
        {...register('address', {
          required: createRequiredValidationRule('address')
        })}
        id="address"
        label={labelsTranslations('ADDRESS')}
        errorMessageDetails={getErrorMessage(errors?.address)}
        disabled={disabledFields?.address}
      />
      <OnboardingTextField
        {...register('postalCode', {
          required: createRequiredValidationRule('postalCode'),
          pattern: createPostalCodePatternValidationRule()
        })}
        id="postalCode"
        type="number"
        label={labelsTranslations('POSTAL_CODE')}
        errorMessageDetails={invalidPostalCodeMessage}
        disabled={disabledFields?.postalCode}
      />
      {disabledFields?.postalCode && (
        <OnboardingDropdown
          options={counties}
          label={'Län'}
          onChange={(countyOption) => setCountyPostalCode(countyOption.value)}
        />
      )}
    </>
  );
};

export default AddressFields;
