import React from 'react';

import { ActivityIndicator } from '@components/index';

import styles from './OnboardingTextField.module.css';
import InputMessage from '../InputMessage';
import { InputMessageProps } from '../InputMessage/InputMessage';

export type OnboardingTextInputProps = {
  id: string;
  label?: string;
  className?: string;
  isLoading?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

type OnboardingTextFieldProps = OnboardingTextInputProps & {
  errorMessageDetails?: InputMessageProps;
};

export const OnboardingTextInput = React.forwardRef<
  HTMLInputElement,
  OnboardingTextInputProps
>(({ id, label, className, isLoading, required, ...props }, ref) => {
  return (
    <div className={`${styles.textInput} ${className || ''}`}>
      <input
        className={styles.input}
        type="text"
        id={id}
        name="onboarding-input"
        placeholder={label}
        ref={ref}
        required={required}
        {...props}
      />
      {label && (
        <label className={styles.label} htmlFor={id}>
          {`${label} ${required ? '*' : ''}`}
        </label>
      )}
      {isLoading && <ActivityIndicator className={styles.loader} />}
    </div>
  );
});

OnboardingTextInput.displayName = 'OnboardingTextInput';

const OnboardingTextField = React.forwardRef<
  HTMLInputElement,
  OnboardingTextFieldProps
>(({ id, label, className, errorMessageDetails, isLoading, ...props }, ref) => {
  return (
    <div>
      <OnboardingTextInput
        id={id}
        label={label}
        className={className}
        ref={ref}
        isLoading={isLoading}
        {...props}
      />
      {errorMessageDetails && errorMessageDetails.title && (
        <InputMessage {...errorMessageDetails} />
      )}
    </div>
  );
});

OnboardingTextField.displayName = 'OnboardingTextField';

export default OnboardingTextField;
