import PersonOutline from '@assets/icons/PersonOutline';
import { Button } from '@components/Button/Button';
import { useLandingPageNavigation } from '@features/landing-page';
import { cn } from '@lib/utils';

import { useCurrentPage } from '../../../../hooks/useCurrentPage';
import styles from './ProfileButton.module.css';

const ProfileButton = () => {
  const { navigateToLogin } = useLandingPageNavigation();
  const { isOnboarding } = useCurrentPage();

  return (
    <Button
      onClick={navigateToLogin}
      variant="primary"
      hugged
      className={cn(styles.profileButton, isOnboarding && styles.hidden)}
    >
      <PersonOutline />
    </Button>
  );
};

export default ProfileButton;
