import { PropsWithChildren } from 'react';

import { useTranslation } from '@core/translation';

import { Block, Button, IconButton, Modal, ModalHeader } from '..';
import { cn } from '../../lib/utils';
import styles from './ActionModal.module.css';

type Props = {
  title: string;
  description: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  onClose?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  containerClassName?: string;
  isLoading?: boolean;
} & PropsWithChildren;

const ActionModal = ({
  title,
  description,
  onSecondaryClick,
  onPrimaryClick,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  children,
  containerClassName,
  isLoading
}: Props) => {
  const t = useTranslation();
  return (
    <Modal className={styles.actionModal}>
      <div className={cn(styles.actionModalContainer, containerClassName)}>
        <Block className={styles.actionModalContent}>
          {children ? (
            children
          ) : (
            <>
              <ModalHeader title={title} description={description} />
              <div className={styles.actionModalButtons}>
                <Button
                  onClick={onPrimaryClick}
                  className={styles.actionModalButtonPrimary}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {primaryButtonText ?? t('COMMON.CONTINUE')}
                </Button>
                <Button onClick={onSecondaryClick} variant="secondary">
                  {secondaryButtonText ?? t('COMMON.CANCEL')}
                </Button>
              </div>
            </>
          )}
        </Block>
        {!!onClose && (
          <IconButton
            name="close"
            onClick={onClose}
            className={styles.actionModalCloseButton}
          />
        )}
      </div>
    </Modal>
  );
};

export default ActionModal;
