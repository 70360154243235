import { useMemo, useState } from 'react';

import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { Button } from '@components/index';
import { useTranslation } from '@core/translation';
import { InputMessageProps, ProcessStepProps } from '@features/onboarding-2';

import { OnboardingFormFields } from '../OnboardingContainer';
import { INVALID_POSTAL_CODE_ERROR_ID } from '../OnboardingContainer/onboardingHandlers';
import { FormFields } from './components';
import usePostalCodeError from './hooks/usePostalCodeError';

type OnboardingFormProps = {
  onSubmit: () => void;
  loading?: boolean;
  errors?: FieldErrors<OnboardingFormFields>;
  validationStateStep2: ProcessStepProps['state'];
  handleInvalidPostalCode: () => void;
  register: UseFormRegister<OnboardingFormFields>;
  setValue: UseFormSetValue<OnboardingFormFields>;
  validationStateStep1: ProcessStepProps['state'];
  prefillIsLoading?: boolean;
  disabledFields?: {
    address: boolean;
    postalCode: boolean;
  };
  setCountyPostalCode: (value: string | null) => void;
};

const createErrorMessages = (
  handleInvalidPostalCode: () => void
): Record<string, InputMessageProps> => {
  return {
    [INVALID_POSTAL_CODE_ERROR_ID]: {
      title: 'ONBOARDING2.PRICE_SUGGEST_FORM.ERRORS.POSTAL_CODE.TITLE',
      message: 'ONBOARDING2.PRICE_SUGGEST_FORM.ERRORS.POSTAL_CODE.DESCRIPTION',
      actionName:
        'ONBOARDING2.PRICE_SUGGEST_FORM.ERRORS.POSTAL_CODE.ACTION_NAME',
      action: handleInvalidPostalCode
    }
  };
};

const OnboardingForm = ({
  onSubmit,
  loading,
  errors,
  validationStateStep1,
  validationStateStep2,
  register,
  handleInvalidPostalCode,
  setValue,
  prefillIsLoading,
  disabledFields,
  setCountyPostalCode
}: OnboardingFormProps) => {
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const ct = useTranslation('COMMON');

  const ERROR_MESSAGES = useMemo(
    () => createErrorMessages(handleInvalidPostalCode),
    [handleInvalidPostalCode]
  );
  const invalidPostalCodeMessage = usePostalCodeError(errors, ERROR_MESSAGES);

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <>
      <FormFields
        register={register}
        invalidPostalCodeMessage={invalidPostalCodeMessage}
        setValue={setValue}
        validationStateStep1={validationStateStep1}
        validationStateStep2={validationStateStep2}
        errors={errors}
        prefillIsLoading={prefillIsLoading}
        setIsSendDisabled={setIsSendDisabled}
        disabledFields={disabledFields}
        setCountyPostalCode={setCountyPostalCode}
      />
      <Button
        onClick={handleSubmit}
        variant="fourth"
        isLoading={loading}
        disabled={isSendDisabled}
      >
        {ct('SEND')}
      </Button>
    </>
  );
};

export default OnboardingForm;
