import { useRouter } from 'next/router';

import ActionModal from '@components/ActionModal/ActionModal';
import { useTrackEvent } from '@features/onboarding-2';

import { useSummaryPageTranslation } from '../../../../translation';

interface ChangeDetailsModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ChangeDetailsModal = ({
  isOpen,
  closeModal
}: ChangeDetailsModalProps) => {
  useTrackEvent;
  const t = useSummaryPageTranslation();
  const { replace } = useRouter();
  const { trackEvent } = useTrackEvent();

  const handleSubmit = () => {
    trackEvent('web_clicked_confirm_change_information_onboarding_2');
    replace('/onboarding');
  };

  return (
    isOpen && (
      <ActionModal
        onPrimaryClick={handleSubmit}
        onSecondaryClick={() => closeModal()}
        title={t('CHANGE_DETAILS_MODAL.TITLE')}
        description={t('CHANGE_DETAILS_MODAL.DESCRIPTION')}
        primaryButtonText={t('CHANGE_DETAILS_MODAL.PRIMARY_BUTTON')}
      />
    )
  );
};

export default ChangeDetailsModal;
