import {
  Block,
  usePriceSuggestionPageTranslation,
  useTrackEvent
} from '@features/onboarding-2';
import { cn } from '@lib/utils';

import { Datepicker } from '../../../../pages/PriceSuggestion';
import BankIDButton from '../BankIDButton/BankIDButton';
import { PriceSuggestionBannerYourPrice } from './components';
import styles from './PriceSuggestionBanner.module.css';

type Props = {
  onAuthClick: () => void;
  isLoading: boolean;
  monthlyPremium: number;
  discountMonthlyPremium: number;
  onDateChange: (date: Date) => void;
  selectedStartDate: Date;
  isVisible: boolean;
};

const PriceSuggestionBanner = ({
  onAuthClick,
  isLoading,
  monthlyPremium,
  onDateChange,
  selectedStartDate,
  isVisible
}: Props) => {
  const t = usePriceSuggestionPageTranslation();
  const { trackEvent } = useTrackEvent();

  const handleAuthClick = () => {
    trackEvent('web_clicked_banner_bank_id_onboarding_2');
    onAuthClick();
  };

  return (
    <Block
      variant="fourth"
      className={cn(styles.block, !isVisible && styles.blockHidden)}
      blockInnerClassName={styles.blockInner}
    >
      <PriceSuggestionBannerYourPrice price={monthlyPremium} />
      <div className={styles.actionsContainer}>
        <Datepicker
          date={selectedStartDate}
          onDateChange={onDateChange}
          startDateContainerClassName={styles.startDateContainer}
          selectedDateClassName={styles.selectedDate}
          iconClassName={styles.calendarIcon}
          containerClassName={styles.datePickerContainer}
          calendarClassName={styles.calendar}
        />
        <BankIDButton
          onClick={handleAuthClick}
          isLoading={isLoading}
          title={t('CONTINUE')}
          size="small"
          className={styles.bankIdButton}
        />
      </div>
    </Block>
  );
};

export default PriceSuggestionBanner;
