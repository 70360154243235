import { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';

import {
  usePriceSuggestionFormErrorsTranslation,
  useTrackEvent
} from '@features/onboarding-2';
import { ErrorResponse } from '@pages/api/onboarding/user/[personalNumber]';

import { useUserDetails, useValidatePersonalNumber } from '../hooks';
import { OnboardingFormFields } from '../pages/Onboarding/components/OnboardingContainer';
import storage from '../storage';
import { useWatchPersonalNumberChange } from './useWatchPersonalNumberChange';

function createInitialValues() {
  const { onboarding } = storage();

  return {
    personalNumber: onboarding?.personalNumber ?? '',
    age: onboarding?.age ?? '',
    address: onboarding?.address ?? '',
    postalCode: onboarding?.postalCode ?? '',
    numberOfResidents: '' // This data is not used to set the value of the input field, so it is kept empty even though there might be data in storage
  } as OnboardingFormFields;
}

function isAxiosErrorWithErrorResponse(
  error: unknown
): error is AxiosError<ErrorResponse> {
  return (
    error instanceof AxiosError &&
    error.response !== undefined &&
    'code' in (error.response.data ?? {})
  );
}

const FAULTY_USER_ERROR = 123; // TODO: Move this to a place where we can reuse and add more codes if necessary

export const useOnboardingForm = () => {
  const { trackEvent } = useTrackEvent();
  const initialDisabledFields = {
    address: false,
    postalCode: false
  };
  const [disabledFields, setDisabledFields] = useState(initialDisabledFields);
  const formMethods = useForm({
    defaultValues: createInitialValues(),
    mode: 'onChange'
  });

  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors }
  } = formMethods;

  const personalNumber = useValidatePersonalNumber();
  const et = usePriceSuggestionFormErrorsTranslation();

  const {
    address,
    postalCode,
    age,
    isLoading: isPersonalNumberLoading,
    prefilledUserDetailsRef
  } = useUserDetails<ErrorResponse>({
    personalNumber,
    onError: (error) => {
      if (
        isAxiosErrorWithErrorResponse(error) &&
        error.response?.data?.code === FAULTY_USER_ERROR
      ) {
        setValue('address', '');
        setValue('postalCode', '');
        setError('postalCode', {
          message: `${et('INVALID_DETAILS.TITLE')} ${et(
            'INVALID_DETAILS.DESCRIPTION'
          )}`
        });
        setDisabledFields({
          address: true,
          postalCode: true
        }); // TODO: Make sure to reset these fields
        return;
      }

      setError('personalNumber', { message: et('COULD_NOT_PREFILL_ADDRESS') });
    }
  });

  const resetDisabledFields = () => {
    setDisabledFields(initialDisabledFields);
  };

  useWatchPersonalNumberChange(watch, personalNumber, resetDisabledFields);

  useEffect(() => {
    if (personalNumber.isValid) {
      trackEvent('web_entered_personal_number_onboarding_2');
    }
  }, [personalNumber.isValid, trackEvent]);

  useEffect(() => {
    if (address && postalCode && age && personalNumber.isValid) {
      setValue('age', age, { shouldDirty: true, shouldValidate: true });
      setValue('address', address, { shouldDirty: true, shouldValidate: true });
      setValue('postalCode', postalCode, {
        shouldDirty: true,
        shouldValidate: true
      });
    }
  }, [
    setValue,
    personalNumber.isValid,
    personalNumber.rawValue,
    age,
    address,
    postalCode
  ]);

  return {
    ...formMethods,
    prefillIsLoading: isPersonalNumberLoading && personalNumber.isValid,
    disabledFields,
    register,
    handleSubmit,
    errors,
    prefilledUserDetailsRef
  };
};
