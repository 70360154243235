import { ActivityIndicator } from '@components/index';
import { date as dateLib } from '@lib/date';
import { cn } from '@lib/utils';

import { QuoteResultProps } from '../';
import { Text } from '../../../../components';
import { useSummaryPageTranslation } from '../../../../translation';
import { Row, Section } from './components';
import styles from './QuoteResultDisplay.module.css';

const getFirstPaymentStartDate = (policyStartDate: string) => {
  return dateLib.format(dateLib.parseISO(policyStartDate), 'dd/MM-yy');
};

const getLastDayOfMonth = (policyStartDate: string) => {
  return dateLib.format(
    dateLib.lastDayOfMonth(dateLib.parseISO(policyStartDate)),
    'dd/MM-yy'
  );
};

const getFirstOfMonthNextYear = (policyStartDate: string) => {
  const policyStartDateParsed = dateLib.parseISO(policyStartDate);
  const dayOfMonth = dateLib.getDate(policyStartDateParsed);
  const firstDayOfMonthDiff = dayOfMonth - 1;

  const firstDayOfMonthNextYear = dateLib.add(policyStartDateParsed, {
    years: 1,
    days: -firstDayOfMonthDiff
  });

  return dateLib.format(firstDayOfMonthNextYear, 'dd/MM-yy');
};

const QuoteResultDisplay = ({
  price,
  unit,
  discount,
  campaignCode,
  firstPremiumAmount,
  premiumBeforeDiscount,
  isQuoteLoading,
  paymentPeriodId,
  policyStartDate
}: QuoteResultProps) => {
  const t = useSummaryPageTranslation();

  const isMonthlyPayment = paymentPeriodId === '2';

  const firstPaymentStartDate = getFirstPaymentStartDate(policyStartDate);
  const firstPaymentEndDate = isMonthlyPayment
    ? getLastDayOfMonth(policyStartDate)
    : getFirstOfMonthNextYear(policyStartDate);

  const discountRow = (
    <Row>
      <Text type="heavy-small">{t('DISCOUNT')}</Text>
      <Text type="normal-small">{discount || t('NO_DISCOUNT')}</Text>
    </Row>
  );

  const campaignCodeRow = (
    <Row>
      <Text type="normal-small">{t('CAMPAIGN_CODE')}</Text>
      <Text type="normal-small">{campaignCode}</Text>
    </Row>
  );

  const firstMonthlyPaymentRow = (
    <Row>
      <Text type="heavy-small">{t('FIRST_PAYMENT')}</Text>
    </Row>
  );

  const firstMonthlyPaymentAmountRow = (
    <Row>
      <Text type="normal-small">
        {t('REFERS_TO', {
          startDate: firstPaymentStartDate,
          endDate: firstPaymentEndDate
        })}
      </Text>
      <Text className={styles.firstPremiumAmount} type="standard">
        {isMonthlyPayment ? `${firstPremiumAmount}kr` : `${price}kr`}
      </Text>
    </Row>
  );
  const firstYearlyPaymentRow = (
    <Row className={styles.firstYearlyPaymentRow}>
      <Text type="heavy-small">{t('PRICE')}</Text>
      <Text type="normal-small">
        {t('REFERS_TO', {
          startDate: firstPaymentStartDate,
          endDate: firstPaymentEndDate
        })}
      </Text>
    </Row>
  );

  const firstYearlyPaymentAmountRow = (
    <Row
      className={cn(
        styles.quoteResultDisplayRow,
        styles.firstYearlyPaymentAmountRow
      )}
    >
      <div className={styles.priceContainer}>
        {discount && (
          <p
            className={styles.premiumPrice}
          >{`${premiumBeforeDiscount?.toFixed()}kr`}</p>
        )}
        <h4>{`${price} ${unit}`}</h4>
      </div>
    </Row>
  );

  const continousPriceRow = (
    <Row>
      <Text type="heavy-small">{t('CONTINUOUS_PRICE')}</Text>
      <div className={styles.priceContainer}>
        {discount && (
          <p
            className={styles.premiumPrice}
          >{`${premiumBeforeDiscount?.toFixed()}kr`}</p>
        )}
        <h4>{`${price} ${unit}`}</h4>
      </div>
    </Row>
  );

  const disocuntSection = (
    <Section>
      {discountRow}
      {campaignCodeRow}
    </Section>
  );

  const monthlyPayment = (
    <Section>
      {firstMonthlyPaymentRow}
      {firstMonthlyPaymentAmountRow}
    </Section>
  );

  const yearlyPayment = (
    <div className={styles.yearlPaymentContainer}>
      {firstYearlyPaymentRow}
      {firstYearlyPaymentAmountRow}
    </div>
  );

  const firstPaymentSection = (
    <Section>{isMonthlyPayment ? monthlyPayment : yearlyPayment}</Section>
  );

  if (isQuoteLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.quoteResultDisplay}>
      {campaignCode && disocuntSection}
      {firstPaymentSection}
      {isMonthlyPayment && continousPriceRow}
    </div>
  );
};

export default QuoteResultDisplay;
