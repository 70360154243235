import { CreateQuoteBody } from './types';
import { createInternalRequest } from '../api-definition';

// FIXME: Copied from src/core/api/apis/insurance-in-a-box/requests/quotes/types.ts
export interface ConvertQuoteToPolicyResponse {
  result: {
    isPolicyCreated: boolean;
    policyId: number;
    policyNumber: string;
    //TODO: WHAT ARE THE OTHER STATUS VALUES?
    status: 'Valid' | 'Draft';
  };
}

// FIXME: Copied from src/core/api/apis/insurance-in-a-box/requests/quotes/types.ts
export interface ConvertQuoteToPolicyRequest {
  quoteId: number;
  paymentId?: string;
  isPayLaterPolicy?: boolean;
}

// TODO: Change name of request
export const convertQuoteToPolicyInternal = createInternalRequest<
  ConvertQuoteToPolicyResponse,
  CreateQuoteBody
>({
  path: 'api/onboarding/pay-later',
  method: 'POST'
});
