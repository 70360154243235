import { PropsWithChildren } from 'react';

import { cn } from '@lib/utils';

import styles from './Section.module.css';

const Section = ({
  children,
  className
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <div className={cn(styles.quoteResultDisplaySection, className)}>
      {children}
    </div>
  );
};

export default Section;
