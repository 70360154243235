const NON_DIGIT_REGEX = /[^0-9+]/g;
const COUNTRY_CODE_REGEX = /^(\+\d{2})/;
const LEADING_ZEROS_REGEX = /^(0046|00|0{2,})/;

const removeCountryCode = (phoneNumber: string): string => {
  const cleanNumber = phoneNumber.replace(NON_DIGIT_REGEX, '');

  const withoutCountryCode = cleanNumber.replace(COUNTRY_CODE_REGEX, '');
  const numberWithoutLeadingZeros = withoutCountryCode.replace(
    LEADING_ZEROS_REGEX,
    ''
  );

  return addLeadingZeroIfMissing(numberWithoutLeadingZeros);
};

function addLeadingZeroIfMissing(telephoneNumber: string): string {
  return telephoneNumber.startsWith('0')
    ? telephoneNumber
    : `0${telephoneNumber}`;
}

export default removeCountryCode;
