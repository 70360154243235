import { useCallback, useState } from 'react';

import { useRouter } from 'next/router';

import { Quote, useErrorCodeTranslation } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTrackEvent } from '@features/onboarding-2';
import InternalApiError from '@lib/errors';
import { logger } from '@lib/logger';

import { callCreatePayment } from '../../Onboarding/quoteService';

const useCreatePaymentRequest = () => {
  const { trackEvent } = useTrackEvent();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const messaging = useMessaging();
  const { getErrorTranslation } = useErrorCodeTranslation();

  const pay = useCallback(
    async (quote: Quote) => {
      if (!quote) {
        logger.log('Payment request data is not available');
        return;
      }

      setIsLoading(true);

      try {
        const data = await handleCreatePayment(quote);

        if ('hostedPaymentPageUrl' in data) {
          trackEvent('web_navigated_to_nets_payment_onboarding_2');
          router.push(data.hostedPaymentPageUrl);
        }
      } catch (error) {
        handleError(error, messaging, getErrorTranslation);
      } finally {
        setIsLoading(false);
      }
    },
    [getErrorTranslation, messaging, router, trackEvent]
  );

  return { pay, isLoading };
};

async function handleCreatePayment(quote: Quote) {
  return await callCreatePayment(quote);
}

function handleError(
  error: Error | unknown,
  messaging: ReturnType<typeof useMessaging>,
  getErrorTranslation: (code: number) => string
) {
  const unknownError = -1;
  const code = error instanceof InternalApiError ? error.code : unknownError;

  messaging.addMessage({
    toastType: 'error',
    message: getErrorTranslation(code)
  });
}

export default useCreatePaymentRequest;
