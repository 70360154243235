import React from 'react';

import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { InputMessageProps, ProcessStepProps } from '@features/onboarding-2';

import { AboutYouStep, YourHomeStep } from '..';
import { OnboardingFormFields } from '../../../OnboardingContainer';

type FormFieldsProps = {
  register: UseFormRegister<OnboardingFormFields>;
  invalidPostalCodeMessage?: InputMessageProps;
  setValue: UseFormSetValue<OnboardingFormFields>;
  validationStateStep1: ProcessStepProps['state'];
  validationStateStep2: ProcessStepProps['state'];
  errors?: FieldErrors<OnboardingFormFields>;
  prefillIsLoading?: boolean;
  setIsSendDisabled: (value: boolean) => void;
  disabledFields?: {
    address: boolean;
    postalCode: boolean;
  };
  setCountyPostalCode: (value: string | null) => void;
};

const FormFields = ({
  register,
  invalidPostalCodeMessage,
  setValue,
  validationStateStep1,
  validationStateStep2,
  errors,
  prefillIsLoading,
  setIsSendDisabled,
  disabledFields,
  setCountyPostalCode
}: FormFieldsProps) => {
  return (
    <>
      <AboutYouStep
        register={register}
        validationStateStep={validationStateStep1}
        errors={errors}
        prefillIsLoading={prefillIsLoading}
      />
      <YourHomeStep
        setIsSendDisabled={setIsSendDisabled}
        register={register}
        setValue={setValue}
        invalidPostalCodeMessage={invalidPostalCodeMessage}
        validationStateStep={validationStateStep2}
        errors={errors}
        disabledFields={disabledFields}
        setCountyPostalCode={setCountyPostalCode}
      />
    </>
  );
};

export default FormFields;
