import axios, { isAxiosError } from 'axios';
import { getCookie } from 'cookies-next';
import { NextApiRequest, NextApiResponse } from 'next';

import env from '@core/env-config';
import { logger } from '@lib/logger';

export type ApiClientError = {
  errorMessage: string;
  code: string | number;
  cause: unknown;
};

export function isApiClientError(error: unknown): error is ApiClientError {
  return (
    error !== null &&
    typeof error === 'object' &&
    'errorMessage' in error &&
    'code' in error &&
    'cause' in error
  );
}

export const createApiClient = (req: NextApiRequest, res: NextApiResponse) => {
  const authKey = getCookie('authKey', { req, res });
  const accessToken = getCookie('accessToken', { req, res });

  const api = axios.create({
    baseURL: env.INSURANCE_IN_A_BOX_URL,
    headers: {
      apiKey: env.INSURANCE_IN_A_BOX_KEY,
      authKey: authKey ?? '',
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  });

  api.interceptors.response.use(
    (response) => response,
    (error): Promise<ApiClientError> => {
      logger.error('API request failed:', error);

      // TODO: Can we also check if it is an IIB error?
      if (isAxiosError(error) && error.response?.data) {
        const errorData = error.response.data;

        return Promise.reject({
          errorMessage: errorData.error.message,
          code: errorData.error.code,
          cause: error
        });
      }

      return Promise.reject({
        errorMessage: 'API request failed',
        code: 'UNKNOWN',
        cause: error
      });
    }
  );

  return api;
};
