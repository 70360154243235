import { Text } from '@features/onboarding-2';

import styles from '../ModalHeader/ModalHeader.module.css';

type ModalHeaderProps = {
  title: string;
  description: string;
};

const ModalHeader = ({ title, description }: ModalHeaderProps) => {
  return (
    <div>
      <h5 className={styles.actionModalTitle}>{title}</h5>
      <Text type="normal-medium" className={styles.actionModalDescription}>
        {description}
      </Text>
    </div>
  );
};

export default ModalHeader;
