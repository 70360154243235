import { useEffect } from 'react';

import { UseFormWatch } from 'react-hook-form';

import { useTrackEvent } from '@features/onboarding-2';

import { OnboardingFormFields } from '../pages/Onboarding/components/OnboardingContainer';

export const useWatchPersonalNumberChange = (
  watch: UseFormWatch<OnboardingFormFields>,
  personalNumber: {
    value: string;
    handleChange: (value: string) => void;
    isValid: boolean;
  },
  resetDisabledFields: () => void
) => {
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    const { unsubscribe } = watch((values) => {
      if (
        values.personalNumber &&
        values.personalNumber !== personalNumber.value
      ) {
        personalNumber.handleChange(values.personalNumber);
        resetDisabledFields();
      }
    });

    return () => unsubscribe();
  }, [watch, personalNumber, resetDisabledFields, trackEvent]);
};
