import { capitalize, capitalizeAll } from './capitalize';
import { cn } from './cn';
import { convertNumericStringToNumber } from './convertNumericStringToNumber';
import { delay } from './delay';
import { generateUUID as uuid } from './generateUUID';
import { getIndexedArray } from './getIndexedArray';
import { getRandomElementInArray } from './getRandomElementInArray';
import { getRandomNumberInRange } from './getRandomNumberInRange';
import { includes } from './includes';
import { isNumber } from './isNumber';
import {
  isEmail,
  isIsoDate,
  isNumeric,
  isPhoneNumber,
  isPostalCode,
  isUrl
} from './regex';
import removeCountryCode from './removeCountryCode';
import { removeSpacesFromString } from './removeSpacesFromString';
import roundNumber from './roundNumber';
import { sameDay } from './sameDay';
import { sortArrayOfObjectsByProperty } from './sortArraysOfObjectsByProperty';
import { useDebounce } from './useDebounce';
import { useInterval } from './useInterval';
import useLocation from './useLocation';
import { useTimeout } from './useTimeout';
import { useToggle } from './useToggle';
import { useTypedRouter } from './useTypedRouter';
import useWindow from './useWindow';
export {
  capitalize,
  capitalizeAll,
  cn,
  convertNumericStringToNumber,
  delay,
  getIndexedArray,
  getRandomElementInArray,
  getRandomNumberInRange,
  includes,
  isEmail,
  isIsoDate,
  isNumber,
  isNumeric,
  isPhoneNumber,
  isPostalCode,
  isUrl,
  removeCountryCode,
  removeSpacesFromString,
  roundNumber,
  sameDay,
  sortArrayOfObjectsByProperty,
  useDebounce,
  useInterval,
  useLocation,
  useTimeout,
  useToggle,
  useTypedRouter,
  useWindow,
  uuid
};
