import { PropsWithChildren } from 'react';

import { cn } from '@lib/utils';

import styles from './Row.module.css';

const Row = ({
  children,
  className
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <div className={cn(styles.quoteResultDisplayRow, className)}>
      {children}
    </div>
  );
};

export default Row;
