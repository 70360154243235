import { useEffect } from 'react';

import { useFeature } from '@core/feature-toggles';
import { useMessaging } from '@core/messaging';
import { usePaymentLink } from '@core/payment';
import { useSignupSuccess } from '@core/signup-success';
import { useTranslation } from '@core/translation';
import { CURRENCY_SYMBOLS, PaymentFrequency } from '@core/types';
import { date } from '@lib/date';
import { isEmail, removeCountryCode, roundNumber, useToggle } from '@lib/utils';
import { NextPageWithLayout } from '@pages/_app';

import { InsuranceProviderModal, PaymentInfoModal } from '../components';
import { PaymentData } from '../components/PaymentInfoModal';
import PriceSuggestionBlock from '../components/PriceSuggestionBlock';
import SummaryList from '../components/SummaryList';
import {
  useCalculateQuote,
  useConvertQuoteToPolicy,
  useCreateQuote,
  usePurchaseLimits
} from '../hooks';
import { useSelectFromPriceStore, useSelectFromUserStore } from '../stores';
import { useTrackEvent } from '../tracking';
import { usePriceSuggestionTranslation } from '../translation';

type OnboardingPaymentProps = {
  onSuccess?: () => void;
  onError?: (err: string) => void;
};

const OnboardingPayment: NextPageWithLayout<OnboardingPaymentProps> = () => {
  const pt = usePriceSuggestionTranslation();
  const tError = useTranslation('ERROR');

  const {
    state: isInsuranceProviderModalVisible,
    toggle: toggleInsuranceProviderModal
  } = useToggle();

  const {
    monthlyPrice,
    price,
    paymentPeriodId,
    startDate,
    discountValue,
    discountMonthlyValue,
    campaignCode
  } = useSelectFromPriceStore((state) => state);

  const { trackEvent } = useTrackEvent();
  const { pay, isLoading: isPaymentLoading } = usePaymentLink();
  const { isLoading, quoteCalculation } = useCalculateQuote({ startDate });
  const { createPolicy, isLoading: isCreatingPolicy } =
    useConvertQuoteToPolicy();

  const { createQuote, isLoading: isQuoteLoading } = useCreateQuote();

  const { toggle: toggleShowPayLaterInformation } = useToggle();

  const { state: showPaymentInfoModal, toggle: togglePaymentInfoModal } =
    useToggle();

  const paymentFrequency: PaymentFrequency =
    paymentPeriodId === 1 ? 'year' : 'month';

  const isYearlyPayment = paymentFrequency === 'year';

  const { enabled, value } = useFeature('pay-later');

  // TODO: Remove year condition once recurring payments are working with pay later
  const isPayLaterToggleActive = enabled && !!value && isYearlyPayment;

  const { email, phoneNumber } = useSelectFromUserStore((state) => state);

  const { handleSignupSuccess } = useSignupSuccess();

  const { addMessage } = useMessaging();
  const { purchaseIsForbidden } = usePurchaseLimits();

  useEffect(() => {
    trackEvent('web_select_start_date');
  }, []);

  const shouldCreateQuote = async () => {
    const result = quoteCalculation?.result;
    if (!result || !email) {
      return null;
    }
    const createQuoteBody = {
      ...result,
      homeData: {
        ...result.homeData,
        paymentPeriodId: paymentPeriodId ?? 2,
        policyStartDate: startDate ?? new Date().toISOString()
      },
      userInfo: {
        ...result.userInfo,
        emailId: email,
        countryCallingCode: '46',
        mobileNumber: phoneNumber ? removeCountryCode(phoneNumber) : ''
      }
    };

    const quoteId = await createQuote(createQuoteBody);
    return quoteId;
  };

  const handlePayLater = async (quoteId: number) => {
    const response = await createPolicy({
      quoteId,
      paymentId: undefined,
      isPayLaterPolicy: true
    });

    if (response?.result.isPolicyCreated) {
      handleSignupSuccess();
    } else {
      addMessage(
        {
          message: tError('POLICY.CREATE_FAILED')
        },
        false
      );
    }
  };

  const handlePayment = async ({ payLater }: { payLater: boolean }) => {
    const quoteId = await shouldCreateQuote();

    if (!quoteId) {
      return;
    }

    if (payLater) {
      await handlePayLater(quoteId);
      return;
    }

    pay({ quoteId, payLater });
  };

  const handleGoToPayment = (willPayLater: boolean) => {
    togglePaymentInfoModal();

    purchaseIsForbidden
      ? addMessage({
          message: tError('PAYMENT.FORBIDDEN'),
          toastType: 'info'
        })
      : handlePayment({ payLater: willPayLater });
  };

  const getPaymentInfo = (): PaymentData | null => {
    const result = quoteCalculation?.result;

    const getOriginalPrice = () => {
      return isYearlyPayment
        ? roundNumber(
            result?.preDiscountPremium ?? price + (discountValue ?? 0)
          )
        : roundNumber(monthlyPrice + (discountMonthlyValue ?? 0));
    };

    if (!result) {
      return null;
    }

    const { homeData } = result;
    const startDate = date.parseISO(homeData.policyStartDate);
    const isToday = date.isToday(startDate);
    const paymentFrequency = isYearlyPayment ? pt('YEARLY') : pt('MONTHLY');

    const paymentPeriodFormat = 'dd/MM-yy';
    const dateFrom = date.format(startDate, paymentPeriodFormat);

    const endDate = isYearlyPayment
      ? date.startOfMonth(date.addYears(startDate, 1))
      : date.startOfMonth(date.addMonths(startDate, 1));

    const dateTo = isYearlyPayment
      ? date.format(endDate, paymentPeriodFormat)
      : date.format(endDate, paymentPeriodFormat);

    const originalPrice = getOriginalPrice();

    return {
      address: {
        street: homeData.address,
        postalCode: homeData.postalCode
      },
      firstPayment: {
        amount:
          roundNumber(
            isYearlyPayment
              ? result.premium || 0
              : result.firstPremiumAmount || 0
          ) + `${CURRENCY_SYMBOLS[result.currency]}`,
        period: pt('FIRST_PAYMENT_INFO', {
          dateFrom,
          dateTo
        })
      },
      isYearlyPayment,
      startDate: isToday
        ? `${pt('TODAY')}, ${date.format(startDate, 'dd MMM yyyy')}`
        : date.format(startDate, 'dd MMM yyyy'),
      paymentFrequency,
      discountCode: campaignCode,
      price: {
        toPay: calculatePriceToPay(result.currency),
        original: formatPriceWithCurrencyIfCampaignCode(
          originalPrice,
          result.currency
        )
      }
    };
  };

  function formatPriceWithCurrencyIfCampaignCode(
    originalPrice: number,
    currency: keyof typeof CURRENCY_SYMBOLS
  ) {
    return (
      campaignCode &&
      `${originalPrice.toString()} ${CURRENCY_SYMBOLS[currency]}`
    );
  }

  function calculatePriceToPay(currency: keyof typeof CURRENCY_SYMBOLS) {
    // FIXME: The yearly price never seems to be used in PaymentInfoModal.tsx
    // Instead to show the yearly price, it looks at firstPayment.amount
    const priceToPay = roundNumber(isYearlyPayment ? price : monthlyPrice);
    const period = isYearlyPayment ? `/${pt('YEAR').toLowerCase()}` : '';

    return `${priceToPay} ${CURRENCY_SYMBOLS[currency]}${period}`;
  }

  const paymentInfo = getPaymentInfo();

  return (
    <div className="no-scrollbar grid h-full flex-auto gap-0.5 overflow-auto md:grid-cols-2 md:grid-rows-1 md:overflow-hidden">
      <PriceSuggestionBlock
        buttonText={pt('SEE_OVERVIEW')}
        onContinueClick={togglePaymentInfoModal}
        fullPrice={
          isYearlyPayment
            ? String(price + (discountValue ?? 0))
            : String(monthlyPrice + (discountMonthlyValue ?? 0))
        }
        discountCode={campaignCode}
        price={isYearlyPayment ? String(price) : String(monthlyPrice)}
        priceUnit={isYearlyPayment ? pt('PRICE_YEAR') : pt('PRICE_MONTH')}
        onPayLaterClick={
          isPayLaterToggleActive ? toggleShowPayLaterInformation : undefined
        }
        isLoading={isLoading}
        isButtonLoading={isPaymentLoading || isQuoteLoading || isCreatingPolicy}
        disabled={!isEmail.test(email ?? '') || isLoading}
        toggleInsuranceProviderModal={toggleInsuranceProviderModal}
        priceInfo={pt('PRICE_INFO')}
        isDiscountCodeEditable
      />
      <SummaryList />
      {isInsuranceProviderModalVisible && (
        <InsuranceProviderModal onClose={toggleInsuranceProviderModal} />
      )}
      {showPaymentInfoModal && paymentInfo && (
        <PaymentInfoModal
          data={paymentInfo}
          onClose={togglePaymentInfoModal}
          onPayLaterConfirm={
            isPayLaterToggleActive && isYearlyPayment
              ? toggleShowPayLaterInformation
              : undefined
          }
          onGoToPaymentClick={handleGoToPayment}
        />
      )}
    </div>
  );
};

export default OnboardingPayment;
