import { useTranslation } from '@core/translation';

export const EMAIL_ID_ALREADY_EXIST = 10010201;
export const CLAIM_POLICY_MATURED_DATE = 10030401;
export const INVALID_DATE_OF_DAMAGE = 10030402;
export const DATE_OF_DAMAGE_GREATER_THAN_POLICY = 10030403;
export const CLAIM_DATA_IS_NOT_EXIST = 10030404;
export const PERSONAL_NUMBER_IS_NOT_VALID = 11000030;
export const NOT_ALLOWED_CANCEL_POLICY = 10070812;
export const USER_DOES_NOT_EXIST = 11020303;
export const NOT_ALLOWED_PAYMENT_DETAILS = 10070813;
export const ALREADY_CLAIM_SHARE = 10030602;
export const IS_USER_SANCTION = 11000029;
export const YOUR_TOKEN_IS_NOT_VALID = 10080001;
export const YOUR_TOKEN_IS_EXPIRED = 10080002;
export const POLICY_UNPAID = 10070023;
export const INVALID_EMAIL_ID = 10010299;
export const POLICY_ALREADY_PAID = 10070022;
export const PAYMENT_ALREADY_DONE = 11050001;
export const INVALID_DISCOUNT_CODE = 11020996;

export const QUOTE_ALREADY_ACCEPTED = 10020301;
export const QUOTE_IS_ALREADY_CONVERTED = 10020302;
export const QUOTE_IS_ALREADY_ACCEPTED = 11050002;
export const POLICY_ID_MANDATORY = 10070001;
export const PARTY_ID_NOT_EXIST = 10070002;
export const CLAIM_ID_NOT_EXIST = 10030301;
export const DOCUMENTS_DATA_IS_NOT_EXIST = 10030501;
export const REQUEST_IS_NOT_VALID = 11000099;
export const PERSONAL_NUMBER_IS_ALREADY_EXIST = 10080003;
export const NOT_ALLOWED_REMOVE_CO_INSURED = 10080101;
export const NOT_ALLOWED_CHANGE_POLICY = 10070012;
export const NOT_ALLOWED_SHARE_CLAIM = 10030601;
export const NOT_ALLOWED_CHANGE_CARD_DETAILS = 10070814;
export const NOT_ALLOWED_CO_INSURED_DETAILS = 10070815;
export const CO_INSURED_DATA_IS_NOT_EXIST = 11050911;
export const POLICY_ALREADY_CANCELLED = 10070816;
export const CANCELLATION_REQUEST_DATE_IS_NOT_VALID = 10070817;
export const POLICY_NUMBER_NOT_EXIST = 10070031;
export const NOT_ALLOWED_CREATE_PAYMENT = 11050021;
export const SSN_DOES_NOT_MATCH = 11020304;
export const INVALID_PARAMETER = 11020305;

export const INVALID_QUOTE_ID = 11020406;
export const PAYMENT_NOT_RECEIVED = 11020407;
export const COMPANY_PAYMENT_ACCOUNT_NOT_EXIST = 11020408;
export const PRODUCT_ID_MANDATORY = 11020509;
export const SSN_NUMBER_NOT_EXIST = 11020510;
export const PAYMENT_PERIOD_MANDATORY = 11020511;
export const POSTAL_CODE_MANDATORY = 11020512;
export const RESIDENTS_NO_MANDATORY = 11020513;
export const PAYMENT_URL_ERROR = 11020614;
export const ERROR_IN_SAVING_PAYMENT_GATEWAY = 11020615;
export const POLICY_DOCUMENT_NOT_EXIST = 11070001;
export const INVALID_OBJECT_CATEGORY = 11070002;
export const OBJECT_DATA_NOT_EXIST = 11070003;
export const SOMETHING_WENT_WRONG = 11070004;
export const OBJECT_ID_MANDATORY = 11070005;
export const NOT_ALLOWED_CHANGE_CARD_PROCESS = 11070006;
export const CARD_DATA_DOES_NOT_EXIST = 11070007;
export const CHANGE_CARD_PROCESS_ONLY_FOR_MONTHLY = 11070008;
export const RETURN_URL_MANDATORY = 11050022;
export const TERM_URL_MANDATORY = 11050023;
export const REFERENCE_ID_MANDATORY = 11050024;
export const INVALID_SERVICE_TYPE = 11050025;

export const PARTY_TYPE_NOT_EXIST = 10020603;
export const QUOTE_PARAM_NOT_EXIST = 10020604;
export const POLICY_DATA_NOT_EXIST = 10070005;
export const INVOICE_DATA_NOT_FOUND = 10070006;
export const PARTY_POLICY_DETAILS_NOT_FOUND = 10070007;
export const POLICY_PARAM_NOT_EXIST = 10070008;
export const PAYMENT_DATA_NOT_FOUND = 10070009;
export const EVENT_ID_NOT_EXIST = 10070010;
export const RISK_ID_NOT_EXIST = 10070011;
export const SETTLEMENT_ID_MANDATORY = 10070024;
export const LEVEL_TYPE_NOT_EXIST = 10070013;
export const SETTLEMENT_DATA_NOT_FOUND = 10070014;
export const CANNOT_UPDATE_YEAR_OF_DAMAGE_DATE = 10070025;
export const INVALID_COVER_NAME = 10070015;
export const SETTLEMENT_AMOUNT_SHOULD_BE_GREATER_EQUAL_TO_ZERO = 10070016;
export const PAYOUT_NOT_FOUND = 10070017;
export const GRID_DATA_NOT_FOUND = 10070018;
export const INVALID_PAGE_SIZE = 10070019;
export const INVALID_TOPIC_NAME = 10070020;
export const MESSAGE_DOCUMENT_DATA_NOT_EXIST = 10070021;

export const useErrorCodeTranslation = () => {
  const t = useTranslation('ERROR');
  const getErrorTranslation = (code?: number) => {
    switch (code) {
      case EMAIL_ID_ALREADY_EXIST:
        return t('IIB.EMAIL_IN_USE');
      case CLAIM_POLICY_MATURED_DATE:
        return t('IIB.EXPIRED_POLICY');
      case INVALID_DATE_OF_DAMAGE:
        return t('IIB.INVALID_DATE');
      case DATE_OF_DAMAGE_GREATER_THAN_POLICY:
        return t('IIB.DATE_BEFORE_POLICY_START');
      case CLAIM_DATA_IS_NOT_EXIST:
        return t('IIB.CLAIM_DATA');
      case PERSONAL_NUMBER_IS_NOT_VALID:
        return t('IIB.INVALID_SSN');
      case NOT_ALLOWED_CANCEL_POLICY:
        return t('IIB.CANCELLATION_INITIATED');
      case USER_DOES_NOT_EXIST:
        return t('IIB.NO_USER_FOUND');
      case NOT_ALLOWED_PAYMENT_DETAILS:
        return t('IIB.PAYMENTS_UNAVAILABLE');
      case ALREADY_CLAIM_SHARE:
        return t('IIB.ALREADY_SHARED_CLAIM');
      case IS_USER_SANCTION:
        return t('IIB.ABORT1');
      case YOUR_TOKEN_IS_NOT_VALID:
      case YOUR_TOKEN_IS_EXPIRED:
        return t('IIB.INVALID_TOKEN');
      case POLICY_UNPAID:
        return t('IIB.POLICY_UNPAID');
      case INVALID_EMAIL_ID:
        return t('IIB.EMAIL_INVALID');
      case POLICY_ALREADY_PAID:
      case PAYMENT_ALREADY_DONE:
        return t('IIB.PAYMENT_ALREADY_DONE');
      case INVALID_DISCOUNT_CODE:
        return t('IIB.INVALID_DISCOUNT_CODE');
      case QUOTE_ALREADY_ACCEPTED:
      case QUOTE_IS_ALREADY_CONVERTED:
      case QUOTE_IS_ALREADY_ACCEPTED:
      case POLICY_ID_MANDATORY:
      case PARTY_ID_NOT_EXIST:
      case CLAIM_ID_NOT_EXIST:
      case DOCUMENTS_DATA_IS_NOT_EXIST:
      case REQUEST_IS_NOT_VALID:
      case PERSONAL_NUMBER_IS_ALREADY_EXIST:
      case NOT_ALLOWED_REMOVE_CO_INSURED:
      case NOT_ALLOWED_CHANGE_POLICY:
      case NOT_ALLOWED_SHARE_CLAIM:
      case NOT_ALLOWED_CHANGE_CARD_DETAILS:
      case NOT_ALLOWED_CO_INSURED_DETAILS:
      case CO_INSURED_DATA_IS_NOT_EXIST:
      case POLICY_ALREADY_CANCELLED:
      case CANCELLATION_REQUEST_DATE_IS_NOT_VALID:
      case POLICY_NUMBER_NOT_EXIST:
      case NOT_ALLOWED_CREATE_PAYMENT:
      case SSN_DOES_NOT_MATCH:
      case INVALID_PARAMETER:
      case INVALID_QUOTE_ID:
      case PAYMENT_NOT_RECEIVED:
      case COMPANY_PAYMENT_ACCOUNT_NOT_EXIST:
      case PRODUCT_ID_MANDATORY:
      case SSN_NUMBER_NOT_EXIST:
      case PAYMENT_PERIOD_MANDATORY:
      case POSTAL_CODE_MANDATORY:
      case RESIDENTS_NO_MANDATORY:
      case PAYMENT_URL_ERROR:
      case ERROR_IN_SAVING_PAYMENT_GATEWAY:
      case POLICY_DOCUMENT_NOT_EXIST:
      case INVALID_OBJECT_CATEGORY:
      case OBJECT_DATA_NOT_EXIST:
      case SOMETHING_WENT_WRONG:
      case OBJECT_ID_MANDATORY:
      case NOT_ALLOWED_CHANGE_CARD_PROCESS:
      case CARD_DATA_DOES_NOT_EXIST:
      case CHANGE_CARD_PROCESS_ONLY_FOR_MONTHLY:
      case RETURN_URL_MANDATORY:
      case TERM_URL_MANDATORY:
      case REFERENCE_ID_MANDATORY:
      case INVALID_SERVICE_TYPE:
      case 11050036: // ErrorInSavingEntity
      case 11050026: // InvalidCompanyId
      case 11050027: // InvalidPaymentServiceType
      case 11050028: // InvalidQuoteStatus
      case 11050029: // AllRequiredFieldsMandatory
      case 11050030: // TransactionIdMandatory
      case 11050031: // InvalidOperationType
      case 11050032: // OperationTypeNone
      case 11050033: // MerchantIdMandatory
      case 11050034: // QuoteDataNotFound
      case 11050035: // CompanyDataNotFound
      case 10070701: // UserIdMandatory
      case 10070702: // FirstNameMandatory
      case 10070703: // LastNameMandatory
      case 10070704: // MobileNumberMandatory
      case 11090101: // ProviderMandatory
      case 11090102: // HostUrlMandatory
      case 11090103: // ProviderBankIdMandatory
      case 11090104: // AuthenticateTokenMandatory
      case 11090105: // IdentityTokenMandatory
      case 10030622: // NotAllowedRegisterClaim
      case 11040501: // ChangePostalCode
      case 11040502: // InvalidPostalCode
      case 11040504: // AddressMandatory
      case 11000020: // ValidDate
      case 11040503: // ChangePaymentMethod
      case 11000010: // InvalidPaymentMethod
      case 11000028: // PersionIdProtected
      case 11030401: // InvalidPaymentId
      case 11000401: // FutureDatePaylaterValidation
      case 11000402: // MonthlyPolicyNotSupportedPaylater
      case 11000301: // FileNameShouldBeMandatory
      case 10020601: // InvalidPackageId
      case 10020602: // PartyDataNotExist
      case PARTY_TYPE_NOT_EXIST:
      case QUOTE_PARAM_NOT_EXIST:
      case POLICY_DATA_NOT_EXIST:
      case INVOICE_DATA_NOT_FOUND:
      case PARTY_POLICY_DETAILS_NOT_FOUND:
      case POLICY_PARAM_NOT_EXIST:
      case PAYMENT_DATA_NOT_FOUND:
      case EVENT_ID_NOT_EXIST:
      case RISK_ID_NOT_EXIST:
      case SETTLEMENT_ID_MANDATORY:
      case LEVEL_TYPE_NOT_EXIST:
      case SETTLEMENT_DATA_NOT_FOUND:
      case CANNOT_UPDATE_YEAR_OF_DAMAGE_DATE:
      case INVALID_COVER_NAME:
      case SETTLEMENT_AMOUNT_SHOULD_BE_GREATER_EQUAL_TO_ZERO:
      case PAYOUT_NOT_FOUND:
      case GRID_DATA_NOT_FOUND:
      case INVALID_PAGE_SIZE:
      case INVALID_TOPIC_NAME:
      case MESSAGE_DOCUMENT_DATA_NOT_EXIST:
      default:
        return t('IIB.DEFAULT');
    }
  };
  return { getErrorTranslation };
};
