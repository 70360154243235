import { useTranslation } from '@core/translation';

export const useOnboardingTranslation = () => useTranslation('ONBOARDING2');

export const usePriceSuggestionLabelsTranslation = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.LABELS');

export const usePriceSuggestionResidentsStates = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.RESIDENTS_STATES');

export const usePriceSuggestionHousingTranslation = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.HOUSING_TYPES');

export const usePriceSuggestionResidencyStatuses = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.RESIDENCY_STATUSES');

export const usePriceSuggestionFormErrorsTranslation = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.ERRORS');

export const usePriceSuggestionFormInvalidCodeModalTranslation = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGEST_FORM.INVALID_POSTAL_CODE_MODAL');

export const usePriceSuggestionPageTranslation = () =>
  useTranslation('ONBOARDING2.PRICE_SUGGESTION_PAGE');

export const useSummaryPageTranslation = () =>
  useTranslation('ONBOARDING2.SUMMARY_PAGE');

export const useSummaryPageErrorsTranslation = () =>
  useTranslation('ONBOARDING2.SUMMARY_PAGE.ERRORS');

export const useFooterTranslation = () => useTranslation('ONBOARDING2.FOOTER');
