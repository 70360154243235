import { useEffect, useState } from 'react';

import {
  CalculateQuoteResponse,
  mutations,
  useErrorCodeTranslation
} from '@core/api';
import { useMessaging } from '@core/messaging';
import { PriceData } from '@core/types';
import { useTemplateData } from '@features/api-playground';

import {
  updatePriceStore,
  useSelectFromPriceStore,
  useSelectFromUserStore
} from '../stores';

type UseCalculateQuoteParams = {
  startDate: string | undefined;
};

const useCalculateQuote = ({ startDate }: UseCalculateQuoteParams) => {
  const {
    address,
    postalCode,
    residents: numberOfResidents
  } = useSelectFromUserStore((state) => state);

  const { getErrorTranslation } = useErrorCodeTranslation();
  const { addMessage } = useMessaging();
  const { data: templateData, isLoading: isLoadingTemplateData } =
    useTemplateData();
  const { mutateAsync: calculateQuoteAsync, isLoading } =
    mutations.quotes.useCalculateQuote({
      onError: (error) => {
        addMessage({
          message: getErrorTranslation(error?.data?.code),
          payload: error
        });
      }
    });

  const {
    price,
    monthlyPrice,
    currency,
    discountMonthlyValue,
    discountValue,
    discountCode,
    campaignCode
  } = useSelectFromPriceStore((state) => state);

  const [quoteCalculation, setQuoteCalculation] =
    useState<CalculateQuoteResponse>();

  useEffect(() => {
    if (!templateData?.result) {
      return;
    }

    const body = {
      ...templateData.result,
      homeData: {
        ...templateData.result.homeData,
        address: address || '',
        city: '',
        numberOfResidents,
        postalCode,
        paymentPeriodId: 2,
        policyStartDate: startDate ?? new Date().toISOString()
      },
      userInfo: {
        ...templateData.result.userInfo
      },
      currency: currency || 'SEK',
      monthlyPremium: monthlyPrice,
      premium: price,
      discountPremiumAmount: discountValue,
      discountMonthlyPremium: discountMonthlyValue,
      discountCode: discountCode || null,
      campaignCode: campaignCode || null
    };

    const calculateQuote = async () => {
      const data = await calculateQuoteAsync(body);
      if (data) {
        const {
          monthlyPremium,
          premium,
          currency,
          discountMonthlyPremium,
          discountPremiumAmount
        } = data.result;

        const priceData: PriceData = {
          price: premium,
          monthlyPrice: monthlyPremium,
          discountMonthlyValue: discountMonthlyPremium
            ? discountMonthlyPremium
            : null,
          discountValue: discountPremiumAmount,
          currency,
          isEstimating: false
        };

        updatePriceStore('updatePrice')(priceData);

        // TODO: should this be needed?
        setQuoteCalculation(data);
      }
    };

    calculateQuote();
  }, [
    templateData,
    currency,
    monthlyPrice,
    price,
    calculateQuoteAsync,
    address,
    numberOfResidents,
    postalCode,
    discountValue,
    discountMonthlyValue,
    campaignCode,
    startDate,
    discountCode
  ]);

  return { isLoading: isLoading || isLoadingTemplateData, quoteCalculation };
};

export default useCalculateQuote;
