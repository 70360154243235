import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ActionModal, ModalHeader } from '@components/index';

import { usePriceSuggestionFormInvalidCodeModalTranslation } from '../../translation';
import EmailInput from '../EmailInput';
import ModalActions from './components/ModalActions';
type InvalidPostalCodeModalProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  onSendEmail: () => void;
  onClose: () => void;
};

const InvalidPostalCodeModal = ({
  email,
  setEmail,
  onSendEmail,
  onClose
}: InvalidPostalCodeModalProps) => {
  const it = usePriceSuggestionFormInvalidCodeModalTranslation();

  return (
    <ActionModal title={''} description={''}>
      <>
        <ModalHeader title={it('TITLE')} description={it('DESCRIPTION')} />
        <EmailInput
          value={email}
          onChange={setEmail}
          id="invalid-postal-code-form"
          className={twMerge('mt-4 text-black')}
        />
        <ModalActions
          onSendEmail={onSendEmail}
          onClose={onClose}
          confirmText={it('CONFIRM_BUTTON')}
          cancelText={it('CANCEL_BUTTON')}
        />
      </>
    </ActionModal>
  );
};

export default InvalidPostalCodeModal;
