import { FieldErrors } from 'react-hook-form';

import { OnboardingFormFields } from './OnboardingContainer';

const PROCESS_STEP2_FIELDS = ['address', 'postalCode']; // TODO: Do we need to add housing here?

type DirtyFields = Partial<
  Readonly<Record<keyof OnboardingFormFields, boolean | undefined>>
>;

type ValidateProcessStep2 = {
  dirtyFields: DirtyFields;
  errors: FieldErrors<OnboardingFormFields>;
  isFaultyUser: boolean;
};

export const isFieldDirty = (
  field: string,
  dirtyFields: Record<string, boolean>
): boolean | undefined => {
  return dirtyFields[field];
};

export const hasDirtyFields = (
  dirtyFields: Record<string, boolean>
): boolean => {
  return Object.entries(dirtyFields).length > 0;
};

export const isFieldInProcessStep2 = (field: string): boolean => {
  return PROCESS_STEP2_FIELDS.includes(field);
};

export const isNoErrorInStep2 = (
  errors: FieldErrors<OnboardingFormFields>,
  exceptions: string[] = []
): boolean => {
  const fieldsToCheck = Object.keys(errors);
  const eligibleFieldsForValidation = fieldsToCheck.filter(
    (field) => !exceptions.includes(field)
  );
  return !eligibleFieldsForValidation.some(isFieldInProcessStep2);
};

export const validateProcessStep2 = ({
  dirtyFields,
  errors,
  isFaultyUser
}: ValidateProcessStep2): boolean => {
  return isFaultyUser
    ? validateProcessStep2WithCounty(errors)
    : validateProcessStep2StandardFlow(dirtyFields, errors);
};

export const validateProcessStep2StandardFlow = (
  dirtyFields: DirtyFields,
  errors: FieldErrors<OnboardingFormFields>
): boolean => {
  return (
    hasDirtyFields(dirtyFields) &&
    PROCESS_STEP2_FIELDS.every((field) => isFieldDirty(field, dirtyFields)) &&
    isNoErrorInStep2(errors)
  );
};

export const validateProcessStep2WithCounty = (
  errors: FieldErrors<OnboardingFormFields>
): boolean => {
  return isNoErrorInStep2(errors, ['postalCode']);
};
