import { useForm, UseFormReturn } from 'react-hook-form';

import { FormData } from '../types';

const useFormSetup = (initialFormData: FormData): UseFormReturn<FormData> => {
  return useForm<FormData>({
    values: initialFormData,
    mode: 'onChange'
  });
};

export default useFormSetup;
