import { FormData } from './types';

const getDefaultFormData = (): FormData => ({
  postalCode: 0,
  residents: 0,
  address: '',
  monthlyPremium: 0,
  paymentPeriodId: '2',
  policyStartDate: new Date().toISOString(),
  emailId: '',
  mobileNumber: ''
});

export { getDefaultFormData };
