import { useState } from 'react';

import { getCampaignByCode, useDeniedOfferInternal } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import {
  storage,
  useSimulateQuote,
  useTrackEvent
} from '@features/onboarding-2';

import InvalidPostalCodeModal from '../../../../components/InvalidPostalCodeModal';
import { useOnboardingForm } from '../../../../hooks/useOnboardingForm';
import { useOnboardingNavigation } from '../../../../hooks/useOnboardingNavigation';
import { OnboardingBlock } from '../OnboardingBlock';
import OnboardingForm from '../OnboardingForm/OnboardingForm';
import {
  handleInvalidPostalCodeEmail,
  handleQuoteError,
  handleSuccessfulQuote
} from './onboardingHandlers';
import { validateProcessStep2 } from './processStep2Validation';

export type OnboardingFormFields = {
  personalNumber: string;
  age: string;
  address: string;
  postalCode: string;
  numberOfResidents: string;
};

type QuoteDataWithCampaign = {
  campaignCode: string | number;
  discountCode: string;
  personalNumber: string;
  age: string;
  address: string;
  postalCode: string;
  numberOfResidents: string;
};

const OnboardingContainer = () => {
  const { trackEvent } = useTrackEvent();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    getValues,
    setValue,
    clearErrors,
    formState,
    prefillIsLoading,
    disabledFields,
    prefilledUserDetailsRef
  } = useOnboardingForm();

  const [isInvalidPostalCodeModalActive, setIsInvalidPostalCodeModalActive] =
    useState(false);

  const { navigateToPriceSuggestion } = useOnboardingNavigation();
  const [email, setEmail] = useState('');
  const { mutate: sendDeniedEmail } = useDeniedOfferInternal();
  const [loading, setLoading] = useState(false);
  const { handleSimulateQuote } = useSimulateQuote();
  const { addMessage } = useMessaging();
  const et = useTranslation('ERROR');
  const [countyPostalCode, setCountyPostalCode] = useState<string | null>(null);

  const handleCampaignCode = async (quoteData: OnboardingFormFields) => {
    const { onboarding } = storage();
    if (!onboarding?.campaignCode) return quoteData;

    const response = await getCampaignByCode(onboarding.campaignCode)().catch(
      () => {
        storage().saveOnboardingStore({ campaignCode: '' });
        return null;
      }
    );

    if (!response) {
      addMessage({
        toastType: 'error',
        message: et('INVALID_DISCOUNT_CODE')
      });
      return null;
    }

    return {
      ...quoteData,
      campaignCode: onboarding.campaignCode,
      discountCode: response.discountCode
    };
  };

  const handleCountyData = (
    quoteData: QuoteDataWithCampaign | OnboardingFormFields
  ) => {
    if (disabledFields.postalCode && countyPostalCode) {
      return {
        ...quoteData,
        postalCode: countyPostalCode
      };
    }

    return quoteData;
  };

  const trackOnboardingEvent = (quoteData: OnboardingFormFields) => {
    const prefilledUserDetails = prefilledUserDetailsRef.current;
    if (!prefilledUserDetails) {
      trackEvent('web_submitted_form_without_personal_number_onboarding_2');
      return;
    }

    const shouldTrackAgeChange = quoteData.age !== prefilledUserDetails.age;
    const shouldTrackPostalCodeChange =
      quoteData.postalCode !== prefilledUserDetails.postalCode;

    const shouldTrackAddressChange =
      quoteData.address !== prefilledUserDetails.address;

    if (shouldTrackAgeChange) {
      trackEvent('web_changed_prefilled_age_onboarding_2');
    }

    if (shouldTrackPostalCodeChange) {
      trackEvent('web_changed_prefilled_postal_code_onboarding_2');
    }

    if (shouldTrackAddressChange) {
      trackEvent('web_changed_prefilled_address_onboarding_2');
    }
  };

  const onOnboardingFormSubmit = async (quoteData: OnboardingFormFields) => {
    try {
      trackOnboardingEvent(quoteData);
      setLoading(true);
      clearErrors();

      const dataWithCampaign = await handleCampaignCode(quoteData);
      if (!dataWithCampaign) return;

      const dataWithCampaignAndHandledCounty =
        handleCountyData(dataWithCampaign);

      const data = await handleSimulateQuote(dataWithCampaignAndHandledCounty);

      if (data) {
        handleSuccessfulQuote(data, navigateToPriceSuggestion);
      }
    } catch (error) {
      handleQuoteError(error, setError);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = () => {
    handleInvalidPostalCodeEmail({
      sendDeniedEmail,
      email,
      postalCode: getValues('postalCode'),
      setIsInvalidPostalCodeModalActive
    });
  };

  const handleCloseModal = () => {
    setIsInvalidPostalCodeModalActive(false);
  };

  const getValidationStateStep2 = (): 'unprocessed' | 'success' | 'error' => {
    if (errors.postalCode && !disabledFields.postalCode) {
      return 'error';
    }

    const { dirtyFields } = formState;
    if (
      validateProcessStep2({
        dirtyFields,
        errors,
        isFaultyUser: disabledFields.postalCode
      })
    ) {
      return 'success';
    }

    return 'unprocessed'; // TODO: This state should change name in to something more descriptive of "not success nor error"
  };

  const getValidationStateStep1 = (): 'unprocessed' | 'success' | 'error' => {
    if (errors.age || errors.personalNumber) {
      return 'error';
    }

    const isStepOneValid = formState.dirtyFields.age && !errors.age;

    if (isStepOneValid) {
      return 'success';
    }

    return 'unprocessed';
  };

  return (
    <>
      <OnboardingBlock>
        <OnboardingForm
          register={register}
          onSubmit={handleSubmit(onOnboardingFormSubmit)}
          loading={loading}
          errors={errors}
          validationStateStep1={getValidationStateStep1()}
          validationStateStep2={getValidationStateStep2()}
          handleInvalidPostalCode={() => {
            setIsInvalidPostalCodeModalActive(true);
          }}
          setValue={setValue}
          prefillIsLoading={prefillIsLoading}
          disabledFields={disabledFields}
          setCountyPostalCode={setCountyPostalCode}
        />
      </OnboardingBlock>
      {isInvalidPostalCodeModalActive && (
        <InvalidPostalCodeModal
          email={email}
          setEmail={setEmail}
          onSendEmail={handleSendEmail}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default OnboardingContainer;

export function transformToSimulateQuoteData(
  // TODO: Should this be added to the useOnboardingForm hook?
  quoteData: OnboardingFormFields,
  residentStates: Record<string, number>
) {
  return {
    ...quoteData,
    postalCode: Number(quoteData?.postalCode),
    numberOfResidents: residentStates[quoteData.numberOfResidents],
    age: Number(quoteData.age)
  };
}
