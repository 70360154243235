import { sv } from 'date-fns/locale';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  registerLocale
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { date as dateLib } from '@lib/date';
import { cn } from '@lib/utils';

import DatepickerHeader from './components/DatepickerHeader';
import StartDateSelector from './components/StartDateSelector';
import styles from './Datepicker.module.css';

registerLocale('se', sv);

type DatepickerProps = {
  onDateChange: (date: Date) => void;
  date: Date;
  maxDate?: Date | null | undefined;
  startDateContainerClassName?: string;
  selectedDateClassName?: string;
  iconClassName?: string;
  containerClassName?: string;
  calendarClassName?: string;
};

const getFormattedTitle = (date: Date): string => {
  if (dateLib.isToday(date)) {
    return dateLib.formatRelative(date, new Date());
  }
  return dateLib.format(date, 'yyyy-MM-dd');
};

const getDayClassName = (currentDate: Date, selectedDate: Date): string => {
  if (dateLib.isSameDay(currentDate, selectedDate)) {
    return cn(styles.day, styles.today);
  }
  return cn(styles.day);
};

const Datepicker = ({
  onDateChange,
  date,
  maxDate,
  startDateContainerClassName,
  selectedDateClassName,
  iconClassName,
  containerClassName,
  calendarClassName
}: DatepickerProps) => {
  const getMaxDate = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 364);
    return maxDate;
  };

  const datePickerConfig = {
    minDate: new Date(),
    startDate: new Date(),
    selected: date || new Date(),
    dateFormat: 'yyyy-MM-dd',
    locale: 'se',
    maxDate: maxDate || getMaxDate(),
    dayClassName: (d: Date) => getDayClassName(d, date),
    weekDayClassName: () => styles.weekday,
    renderCustomHeader: (props: ReactDatePickerCustomHeaderProps) => (
      <DatepickerHeader {...props} />
    ),
    customInput: (
      <StartDateSelector
        selectedDate={getFormattedTitle(date)}
        startDateContainerClassName={startDateContainerClassName}
        selectedDateClassName={selectedDateClassName}
        iconClassName={iconClassName}
      />
    )
  };

  const styleConfig = {
    popperClassName: styles.popper,
    wrapperClassName: styles.wrapper,
    calendarClassName: cn(styles.calendar, calendarClassName)
  };

  return (
    <div className={cn(styles.container, containerClassName)}>
      <DatePicker
        {...datePickerConfig}
        {...styleConfig}
        onChange={onDateChange}
      />
    </div>
  );
};

export default Datepicker;
